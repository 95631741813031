.profile_container{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    min-width: 360px;

}
.profile_container_main{
    width: 95%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    height: 80vh;
}
.reports_cont{
    width: 90%;
    margin: 20px auto;

}
@media screen and (max-device-width: 360px) {
    .profile_container{
        width: 90%;
        /* margin: 0 auto; */
        margin-left: 364px;
        padding-left: 20px;
        box-sizing: border-box;
        min-width: 600px;
        overflow-x: scroll;
    }
    .profile_container_main{
        height: 75vh;
        flex-wrap: wrap;

    }
}
.reports_item{
    width: 200px;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    margin: 5px auto;
    text-align: center;
    letter-spacing: 2px;
    padding: 5px;
}
.report__title{
    width: 100%;
    text-align: center;
    padding: 10px;
    font-weight: 600;
}
.listTable{
    width: 100%;
    margin: 0 auto;
}
.report_calendar_item{
    background-color: #7cb305;
    color: #fff;
    text-align: center;
}
.report_calendar_item_all{
    background-color: #7cb305;
    color: #fff;
    text-align: center;
    width: 70%;
    font-size: 12px;
    display: inline-block;
    float: right;
    cursor: pointer



}
.modal_cont{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: flex-start;
}
.modal_item{
    width: 50%;
}
.images{
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
}
.images img{

    margin: 5px;
}
.obj_name{
    font-size: 20px !important;
}

.calendar_all .ant-picker-calendar-date-content{


    height: auto !important;

}
.addStaffBtn{
    width: 100%;
    display: block;
    margin: 5px 0 5px 20px;
    height: 25px;
}