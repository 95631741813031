.ant-btn-primary {
    color: #fff;

    background: linear-gradient(
            90deg
            , rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 0 !important;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;
}