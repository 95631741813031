.ant-upload-list {

    overflow-y: scroll;
    text-align: center;
}
.novision{
    display: none;
}
.ant-form-item-control-input-content {

    width: 200px;
}

.sel{
    width: 50%;
    margin: 5px auto;
    display: block;
}
.ant-input{
    width: 100%;
}
.ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-bottom: 0;

}
.ant-picker {
     float: none;

    margin: 5px auto 20px auto;
}

.ant-btn-primary {
    color: #fff;
    /* border-color: #1890ff; */
    /* background: #1890ff; */
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 10px;

}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #cccccc;
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 10px;
}
.ant-checkbox + span {
    padding-right: 15px;
    padding-left: 10px;
    min-width: 250px;
    margin-right: 15px;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    color: #545f60;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #e4eced !important;
}