

.main__cont{

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
.button_down{
    width: 292px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.selobj{
    width: 292px;
}