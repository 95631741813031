#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}
.content{
    display: flex;
    width: 100%;

}
.slider{
  /*  background: none;*/
    /*background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%);*/
}
.slider::selection{
   /* background: none !important;*/
   /* color: #fff;*/
    /*background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%);*/
}
.slider__main{
   /* background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%) !important;*/
}
#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}
.site-layout-background{
    min-height: 95vh;
}