.main_container{
    width: 100%;
    overflow-y: scroll;
    height: 60vh;
}
.main_container_1{
    width: 100%;
    overflow-y: scroll;
    height: 80vh;
    max-width: 800px;
    margin: 0 auto;
}
.planTitle{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-weight: 600;
}
.logo{
    text-align: center;
    width: 100%;
    margin: 15px auto 5px;
}
.logo img{
    width: 150px;

}
.team_container{
    width: 95%;
    margin: 10px auto 50px;

}
.team_title{
    font-weight: 600;
    padding: 5px;
    box-sizing: border-box;
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    text-align: center;
    color: #fff;
}
.team_props{
    padding-left: 10px;
    box-sizing: border-box;
}
.sub_container{
    min-width: 40%;
}
.flex_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
}
.object_container{
    border: 1px solid green;
    border-radius: 10px;
    padding: 10px;
    margin: 10px auto;
}
.main__comment{
    width: 95%;
    margin: 5px auto;
}