@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Raleway:ital,wght@1,300&display=swap');


:root {
    --color-black: #484848;
    --color-white: #fff;
    --color-broun: #6e6e6e;
}


/** services styles **/
.noselect{
    user-select: none;
}
.texthover:hover{
    color: #daffe2;
}

.pointer{
    cursor: pointer;
}








.header{
    display: flex;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-family: 'Noto Sans', sans-serif;
    min-width: 320px;
    width: 100%;
    height: 5vh;
    flex-direction: column;
    min-height: 45px;
}





.status{
    display: block;
    width: 100%;
    min-width: 320px;
    float: left;
    position: absolute;
    top: 10px;
    color: white;


}

.status__label{
    font-weight: bold;
    min-width: 70px;
    text-align: right;
    display: block;
    float: right;
    color: var(--color-white);
}
.status__loginname{
    color: var(--color-white);
    min-width: 120px;
    text-align: left;
    display: block;
    float: right;
    padding-left: 5px;
}
.status__exit{
    color: var(--color-white);
    min-width: 100px;
    text-align: center;
    font-weight: bold;
    display: block;
    float: right;

}

@media screen and (max-width: 780px) {
    .header{

        font-size: 10px;
        padding: 5px;



    }
}
