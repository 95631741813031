

.financeStaffCont{
    width: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #000;
}
.financeStaffTitle{
    width: 100%;
    text-align: center;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 14px;
    margin-top: 20px;
}
.financeStaffContent{
    width: 100%;
    text-align: center;
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 20px;
}
.mainCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}
.maintitle{
    text-align: center;
    font-weight: bold;
}
.status__cont{
    font-size: 10px !important;
}
.status__cont__item{
    text-align: left;
    margin: 5px auto;
}
.statistic_cont{
    width: 90%;
    margin: 10px auto;
}
.statistic_cont_item{
    width: 100%;
    border: 4px solid #45cf2c;
    border-radius: 10px;
    margin-bottom: 10px;
}
.statistic_cont_item_header{
    width: 100%;
    background: #45cf2c;
    text-align: center;
    color: #fff;
}
.statistic_cont_item p{
padding-left: 20px;
}
.statistic_cont_item_red{
    width: 100%;
    border: 4px solid #cf2201;
    border-radius: 10px;
    margin-bottom: 10px;
}
.statistic_cont_item_header_red{
    width: 100%;
    background: #cf2201;
    text-align: center;
    color: #fff;
}
.statistic_cont_item_red p{
    padding-left: 20px;
}
.statistic_cont_item_orange{
    width: 100%;
    border: 4px solid #cf8000;
    border-radius: 10px;
    margin-bottom: 10px;
}
.statistic_cont_item_header_orange{
    width: 100%;
    background: #cf8000;
    text-align: center;
    color: #fff;
}
.statistic_cont_item_orange p{
    padding-left: 20px;
}
.statistic_cont_item_blue{
    width: 100%;
    border: 4px solid #16c0cf;
    border-radius: 10px;
    margin-bottom: 10px;
}
.statistic_cont_item_header_blue{
    width: 100%;
    background: #16c0cf;
    text-align: center;
    color: #fff;
}
.statistic_cont_item_blue p{
    padding-left: 20px;
}
.statistic_cont_item_black{
    width: 100%;
    border: 4px solid #446062;
    border-radius: 10px;
    margin-bottom: 10px;
}
.statistic_cont_item_header_black{
    width: 100%;
    background: #446062;
    text-align: center;
    color: #fff;
}
.statistic_cont_item_black p{
    padding-left: 20px;
}
.pop{
    height: 20vh;
    overflow-y: scroll;
}
.title_2{
    text-align: center;
    margin: 10px auto 5px;
    padding-top: 10px;
}