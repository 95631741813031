.ant-checkbox + span {
    min-width: 400px !important;
}

.label_cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.label_name{
    width: 200px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
}
.label_props{
    width: 100px;
    display: block;
    float: left;
    margin-left: 5px;
    box-sizing: border-box;
    min-width: 100px;
}
.label_props__one{
    width: 150px;
    display: flex;
    float: left;
    box-sizing: border-box;
    min-width: 150px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}