*{
    padding: 0;
}

.addBtn{
    margin: 10px 0;
}
.content{
    margin: 10px auto;
    width: 95%;


}
.step{
    margin: 30px auto;
}
.chbx{
    color: rgba(0, 0, 0, 0.85) !important;
}
.menu{
    height: 100%;
    margin: 10px auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
}

.carsCard{
    width: 200px;
     cursor: pointer;
     filter: grayscale(100%);
    display: block;
    float: left;
 }
.titleCard{
   text-align: center;
}

.propsCard{
    width: 200px;
    cursor: pointer;
    display: block;
    text-align: center;
}
.titlePropsCard{
    text-align: center;
}




.active, .carsCard:hover{
    filter: grayscale(0%);
    border-bottom: 2px solid #1890ff;
}

.carImg{
    width: 100%;

}
.containerImg{


}

.title{

}


.anticon svg {
   margin-left: 0px !important;
}
.ant-tag {
    margin-right: 0px !important;
}


.example{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}


.card_content{
    height: 75vh;
}
.card_container{
    width: 90%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    margin: 0 auto;

}

.preview__container{
    width: 100%;
}

.preview__title{
    width: 100%;
}
.preview__container_image{
    width: 100%;
}

.planning__menu{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
}
.planning__menu__item{
    width: 130px;
    margin: 10px 2%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    filter: grayscale(100%);
    text-align: center;

}
.planning__menu__item img{
    width: 100%;

}
.planning__menu__item:hover{
    border: 1px solid #1890ff;
    filter: grayscale(0%);
    font-weight: bold;

}
.planning__menu__item__title{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

}
.planning__menu__header{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    margin: 10px auto;
}
.planTitle__select__cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px auto 0;
}
.planTitle__select__title{
    line-height: 20px;
    min-width: 80px;
    font-size: 12px;
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.row__container{

}
.comment{
    width: 100%;
    font-size: 12px;
}
.comments_content{
    min-height: 150px;
    max-height: 150px;
    text-align: left;
}
.comment__title{
    font-size: 16px;
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.team_title{
    width: calc(100% - 10px);
    text-align: center;
    background: rgb(16,134,103);
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    padding: 5px;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
}

.team_title__name{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.team_title__props{
    font-size: 13px;
    width: 100%;

}
.listTable{
    width: 80%;
    margin: 0 auto;
}
.report_calendar_item{
    background-color: #0f3d31;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
}
.calendar_title{
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 5px;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 600;
}


.change__popover__read{
    background-color: #33b200;
    color: #fff;
    opacity: .8;
    cursor: pointer;
}
.change__popover__change{
    background-color: #f50;
    color: #fff;
    opacity: .8;
    cursor: pointer;
}
.change__popover__change:hover, .change__popover__read:hover{
    opacity: 1;
}

.inputForCommentCont{
    width: 95%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
}
.inputForComment{
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 12px;
    margin: 10px auto;
}