.main {
    display: flex;
    justify-content: center;
    max-width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.alert{
    margin-top: 20px;
    text-align: center;
}
.video{
    display: block;
    position: relative;
    z-index: 0;
    margin: -15% auto 0;
    width: 125%;
}
.ant-form {
/*
    background: #00000042 !important;
    padding: 20px 40px !important;
    border-radius: 5px !important;*/
    background: none !important;
}
.ant-form-item-label > label {

    color: #9f9f9f !important;

}

label{
    color: #9f9f9f !important;
}
.video_wrap {
    position: absolute;
    overflow: hidden;

    z-index: -999;
    min-height: 870px;
    max-height: 870px;
    display: block;
    width: 100%;
    max-width: 2560px;
}
.select__cont{
    display: flex;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    flex-wrap: nowrap;
}
.select__btn{

    color: #fff;

    background: linear-gradient(
            90deg
            , rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 0 !important;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;

}