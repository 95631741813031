.ant-card-body{
    padding: 0;
}
.ant-card-actions{
    float: left;
}
.ant-list-item{
    padding: 5px;
}
.text{
    width: 100% !important;
}
.comment{
    height: 150px;
}
.buttunCont{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.buttunCont button{
    margin: 5px;
    border-radius: 0;
}
.pdfmodal{
    width: 100% !important;
}
.pdfview{
    width: 100%;
    height: 72vh;
}
.ant-list-item-meta-description {

    font-size: 12px;

}
.ant-card-actions > ul {
    position: absolute;
    bottom: 10px;
    left: 10px;

}
.ant-card-actions > li {
margin: 0;
}
.ant-checkbox + span {
    padding-right: 15px;
    padding-left: 10px;
    min-width: 142px;
    margin-right: 15px;
    text-overflow: ellipsis;
    max-width: 142px;
    width: 141px;
    white-space: nowrap;
    overflow: hidden;
}

.ant-select {
    margin-bottom: 10px;
    font-size: 12px;
    min-width: 170px;
}
.ant-modal-body {
overflow-y: scroll;
    max-height: 70vh;
    min-height: 70vh;
}
.ant-radio-group {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
}

.ant-picker-cell-disabled div .ant-picker-calendar-date-content div{
    background-color: #ccc;
}