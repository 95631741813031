body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;


}
a{
  text-decoration: none;
}
li{
  text-decoration: none;
  list-style: none;
}
.ant-form {

  /*background: #00000042 !important;*/
  padding: 20px 40px !important;
  border-radius: 5px !important;
  margin: 10% auto !important;

}
.ant-layout-content{
  min-height: 91vh !important;
  max-height: 100% !important;
  overflow-y: scroll !important;
}

.ant-tag {
  margin-right: 0px !important;
}
@media (max-width: 575px){
.ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
  /* flex: 0 0 100%; */
  /* max-width: 100%; */
  min-width: 200px;
}
}
