.header{
    display: flex;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    line-height: 22px;
    font-family: 'Noto Sans', sans-serif;
    min-width: 320px;
    width: 100%;

    flex-direction: column;


}
.status{
    width: 100%;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    color: #a39797;
    height: 8vh;
    min-height: 50px;

}
.main{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    min-height: 320px;

}
.mobcontent{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: stretch;
    overflow-y: scroll;
    height: 87vh;
}
.mobfooter{
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: white;
    font-size: 10px;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    line-height: 20px;
    min-height: 20px;
    width: 100%;
    height: 5vh;
}
.logo{

}
.imgcontainer{
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    transition: 1s; /*Скорость перехода состояния элемента*/
    animation: show 4s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    animation-delay: 100ms; /* Задержка перед началом */
}

.noaction{
    width: 100%;
    text-align: center;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    color: #939191;
    display: flex;
    align-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 500;
}


.example{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}

@keyframes show{
    0%{
        opacity:0;
    }
   50% {
        opacity:1;
    }
    60% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}
@keyframes hide{
    0%{
        opacity:0;
    }
    100% {
        opacity:1;
    }
}



 .button_container{
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-content: center;
     align-items: center;
     height: 80vh;
 }
 .button_container_up{
     width: 100%;
     display: flex;
     justify-content: flex-start;
     align-content: center;
     align-items: center;

     flex-direction: row;
 }
 .mobile_btn{
    width: 70%;
    margin: 10px auto;
    display: block;
 }
.mobile_btn_1{
    width: 50%;
    margin: 20px auto;
    display: block;
}

 .mobile_btn_up{
     width: 50px;
     text-align: center;
 }
 .navigate{
     width: 50px;
     text-align: center;
 }
 .navigate_cont{
     position: absolute;
     bottom: 35px;
     left: 20px;
 }
 .navigate_cont_r{
     position: absolute;
     bottom: 35px;
     right: 20px;
 }
 .chekContainer{
     overflow-y: scroll;
     margin-top: 10px;

 }

 .uploadbtn{
     display: block;
     width: 200px;
     margin: 0 auto;
 }

 .uploadCont{
     width: 100%;
     margin: auto;
     display: block;
     padding: 15px;
     text-align: center;
     height: 10vh;
 }
 .reportTitle{
     width: 100%;
     text-align: center;

     color: #9f9f9f;
     font-weight: 500;
     display: block;
 }
 .reportTitle_new{
     width: 100%;
     text-align: center;
     background-color: #ffffff;
     color: #9f9f9f;
     margin-top: 10px;
     margin-bottom: 20px;
     font-weight: 500;
     display: block;
 }
 .comment{
     width: 90%;
 }

 .comment_1{
     width: 200px !important;
     text-align: center;
 }
.time_cont{
    width: 100%;
}
 .timePicker{
     width: 60%;
     display: block;
}
 .timePicker input{
     font-size: 14px;
 }
.time{
width: 100%;
    display: block;
    margin: 5px auto;
}
.timeLabel{
width: 100%;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
}
.next{
    display: block;
    width: 200px;
    margin: 10px auto;
}
.uploadContainer{
    width: 90%;
    margin: 20px auto;
    text-align: center;
    border: 1px dashed #ccc;
}
.status_line{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
}

.new__plan__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    height: 87vh;
    overflow-y: scroll;

}
.new__plan__title span{
    font-weight: 600;
}

.new__plan__staff_container{
    width: 100%;
    background-color: #f1ee97;
    color: #525652;
    padding: 0 10px 10px 10px;
    margin: 0 auto;
}
.new__plan__staff_title{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #292a1d;
    padding: 10px;
}
.new__plan__staff_content{
    width: 100%;
    background-color: #ffff;
    padding: 10px;
}
.new__plan__objects_container{
    width: 100%;
    background-color: #adf197;
    color: #525652;
    padding: 0 10px 10px 10px;
    margin: 10px auto;

}
.new__plan__objects_title{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #292a1d;
    padding: 10px;
}
.new__plan__objects_content{
    width: 100%;
    background-color: #ffff;
    padding: 10px;
    font-size: 12px;
}
.new__plan__objects_content div, .new__plan__staff_content div{
    font-size: 12px;
}
.new__plan__mobile_btn{
    width: 150px;
    margin: 10px auto 0;
    font-size: 12px;
    display: block;
    height: 25px;
    line-height: 12px;
    border-radius: 0 !important;
}
.new__plan__container__report div{
    font-size: 12px;
    box-sizing: border-box;

}
.new__plan__report__title{
    text-align: center;
    padding: 10px;
    font-size: 16px!important;
    font-weight: 600;

}
.new__plan__container__report{
    max-height: 87vh;
}

.oldreport_workname{
    width: 100%;
    background: #adf197;
    color: #525652;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px auto;
    font-size: 12px;
}
.oldreport_comment{
    width: 100%;

    color: #525652;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px auto;
    font-size: 12px;
}
.oldreport_cont{
    height: 80vh;
    overflow-y: scroll;
}
.work__cont{
    border: 1px solid #ccc;
    margin-bottom: 5px;
}
.radio__cont{
    width: 100%;
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: space-around;
    flex-wrap: nowrap;
}
