*{
    margin: 0;
    padding: 0;
}


.video{
    width: 100%;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    height: auto;
}
.ant-layout-content, .site-layout-background{
    padding: 0;
}