.ant-layout-sider-zero-width-trigger{
    top: 0;
}
.events {
    margin: 0;
    padding: 0;
    list-style: none;
}
.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 28px;
    text-align: center;
}
.notes-month section {
    font-size: 28px;
}
.ant-radio-group {
    text-align: center;
    margin-right: 10px;
    width: 170px !important;
}
.ant-radio-group label{
    margin: 0 10px 0 10px !important;

}


.ant-picker-calendar-header {
    display: flex;
    justify-content: flex-end !important;
    padding: 12px 0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
/*    line-height: 6px !important;*/
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
/*    height: 60px!important;*/
}