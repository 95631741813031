.countPack,.count{
    display: block;
    float: right;
    width: 50%;
    text-align: right;
}
.count{

}
.countPack{

}
.containerCount{
    display: block;
    width: 100%;
}
.radiomodule{

}
.radiomodule span{

}

.ant-radio-button-wrapper{
    color: rgba(0, 0, 0, 0.85) !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #fff !important;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {

}
.ant-radio-group-small .ant-radio-button-wrapper {

    font-size: 12px;
}
.ant-radio-group {
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
}


.ant-radio-button-wrapper{

}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {

    font-size: 12px;

}
.select_measure{
    border: 1px solid #ccc;
    padding-top: 5px;
    height: 120px;

}
.ant-modal-body p{
    margin: 5px auto;

}
.select_measure p{
    margin-bottom: 0 !important;
}
.ant-input{
    width: 200px;

}
.label{
    text-align: center;
    float: left;
    display: block;
    width: 120px;
    line-height: 30px;
}
.label_3{
    text-align: left;
    float: left;
    display: block;
    width: 180px;
    line-height: 30px;

}
.label_2{
    text-align: center;
    float: right;
    display: block;
    line-height: 30px;
}
.label_cont{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
}
.tabs_of_storeTypes{
    margin: 0;
    -webkit-user-select: none;
    user-select: none;

}
.hide{
    display: none;
}
.btnTransfer{
    margin-right: 5px;
}

.ant-btn-primary.changeIco {
    color: #fff;
    border-color: #fff;
    background: #e52e05c7;
}
.transfer{
    margin: 10px auto;
    width: 90%;
}
.example{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}
.addBtn{
    margin-right: 10px;
    float: right;
}
.inpChange, .sel{
    margin-bottom: 5px;
}
.label_4{
    margin-bottom: 10px;
    float: right;
}
.ant-form-item-label label {
color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker{
    float: left;
    margin-left: 98px;
}
.store_container{

    margin: 0 0 0 10px;
    height: 76vh;

}
.storeBtns{
    margin: 0 0 0 10px;
    height: 5vh;
}
.store_tabs{
    margin: 0 0 0 10px;
    height: 7vh;
    user-select: none;
}
.ant-table-sticky-holder{
    min-height: 10vh;
    font-size: 12px;
}
.ant-layout-footer{
    height: 5vh;
}
.search{
    width: 200px;
    display: block;
    margin-right: 10px;
    float: left;

}
.ant-select{
    margin-bottom: 10px;
}
.ant-input {
    width: 62%;
    margin: 5px auto;
}