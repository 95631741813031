@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Raleway:ital,wght@1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;


}
a{
  text-decoration: none;
}
li{
  text-decoration: none;
  list-style: none;
}
.ant-form {

  /*background: #00000042 !important;*/
  padding: 20px 40px !important;
  border-radius: 5px !important;
  margin: 10% auto !important;

}
.ant-layout-content{
  min-height: 91vh !important;
  max-height: 100% !important;
  overflow-y: scroll !important;
}

.ant-tag {
  margin-right: 0px !important;
}
@media (max-width: 575px){
.ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
  /* flex: 0 0 100%; */
  /* max-width: 100%; */
  min-width: 200px;
}
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.auth_main__3BQhR {
    display: flex;
    justify-content: center;
    max-width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.auth_alert__3bPo4{
    margin-top: 20px;
    text-align: center;
}
.auth_video__vRmBt{
    display: block;
    position: relative;
    z-index: 0;
    margin: -15% auto 0;
    width: 125%;
}
.auth_ant-form__I8UF2 {
/*
    background: #00000042 !important;
    padding: 20px 40px !important;
    border-radius: 5px !important;*/
    background: none !important;
}
.auth_ant-form-item-label__jLEoS > label {

    color: #9f9f9f !important;

}

label{
    color: #9f9f9f !important;
}
.auth_video_wrap__uSgPG {
    position: absolute;
    overflow: hidden;

    z-index: -999;
    min-height: 870px;
    max-height: 870px;
    display: block;
    width: 100%;
    max-width: 2560px;
}
.auth_select__cont__d6dio{
    display: flex;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    flex-wrap: nowrap;
}
.auth_select__btn__7p60c{

    color: #fff;

    background: linear-gradient(
            90deg
            , rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 0 !important;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;

}
:root {
    --color-black: #484848;
    --color-white: #fff;
    --color-broun: #6e6e6e;
}


/** services styles **/
.header_noselect__16cYj{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.header_texthover__DLfGq:hover{
    color: #daffe2;
}

.header_pointer__2RN5T{
    cursor: pointer;
}








.header_header__2dC4U{
    display: flex;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-family: 'Noto Sans', sans-serif;
    min-width: 320px;
    width: 100%;
    height: 5vh;
    flex-direction: column;
    min-height: 45px;
}





.header_status__2QUWd{
    display: block;
    width: 100%;
    min-width: 320px;
    float: left;
    position: absolute;
    top: 10px;
    color: white;


}

.header_status__label__CBoYG{
    font-weight: bold;
    min-width: 70px;
    text-align: right;
    display: block;
    float: right;
    color: #fff;
    color: var(--color-white);
}
.header_status__loginname__cSU3m{
    color: #fff;
    color: var(--color-white);
    min-width: 120px;
    text-align: left;
    display: block;
    float: right;
    padding-left: 5px;
}
.header_status__exit__3ma3e{
    color: #fff;
    color: var(--color-white);
    min-width: 100px;
    text-align: center;
    font-weight: bold;
    display: block;
    float: right;

}

@media screen and (max-width: 780px) {
    .header_header__2dC4U{

        font-size: 10px;
        padding: 5px;



    }
}

#main_components-layout-demo-responsive__1WNfJ .main_logo__uyOUy {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.main_site-layout-sub-header-background__DpXgW {
    background: #fff;
}

.main_site-layout-background__3pWdE {
    background: #fff;
}
.main_content__1yN4g{
    display: flex;
    width: 100%;

}
.main_slider__3bUSU{
  /*  background: none;*/
    /*background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%);*/
}
.main_slider__3bUSU::selection{
   /* background: none !important;*/
   /* color: #fff;*/
    /*background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%);*/
}
.main_slider__main__3zfVI{
   /* background: linear-gradient(90deg, rgba(15,15,15,1) 30%, rgba(16,134,103,1) 85%) !important;*/
}
#main_components-layout-demo-side__Js-Pf .main_logo__uyOUy {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.main_site-layout__1K3PU .main_site-layout-background__3pWdE {
    background: #fff;
}
.main_site-layout-background__3pWdE{
    min-height: 95vh;
}
.staff_addStaffBtn__1-qto{
    width: 100%;
    display: block;
    margin: 5px 0 5px 20px;
    height: 25px;
}
.staff_addStaffCont__3YDhN{
    height: 85vh;
    overflow-y: scroll;
}
.staff_exit__Qmjz_{
    width: 100%;
    text-align: right;
    margin: 10px 0;
    padding-right: 20px;
}
.staff_checkaval__1kvIM{
    display: block;
    float: left;
}
.ant-btn-primary {
    color: #fff;

    background: linear-gradient(
            90deg
            , rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 0 !important;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;
}


.finance_financeStaffCont__1qD-Z{
    width: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #000;
}
.finance_financeStaffTitle__2XZM6{
    width: 100%;
    text-align: center;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 14px;
    margin-top: 20px;
}
.finance_financeStaffContent__3FjGJ{
    width: 100%;
    text-align: center;
    display: block;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 20px;
}
.finance_mainCont__2Ruk8{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}
.finance_maintitle__1DFQ0{
    text-align: center;
    font-weight: bold;
}
.finance_status__cont__273qM{
    font-size: 10px !important;
}
.finance_status__cont__item__2NUEq{
    text-align: left;
    margin: 5px auto;
}
.finance_statistic_cont__2rsW9{
    width: 90%;
    margin: 10px auto;
}
.finance_statistic_cont_item__10rUD{
    width: 100%;
    border: 4px solid #45cf2c;
    border-radius: 10px;
    margin-bottom: 10px;
}
.finance_statistic_cont_item_header__3HUNo{
    width: 100%;
    background: #45cf2c;
    text-align: center;
    color: #fff;
}
.finance_statistic_cont_item__10rUD p{
padding-left: 20px;
}
.finance_statistic_cont_item_red__2CTxb{
    width: 100%;
    border: 4px solid #cf2201;
    border-radius: 10px;
    margin-bottom: 10px;
}
.finance_statistic_cont_item_header_red__1A6Gq{
    width: 100%;
    background: #cf2201;
    text-align: center;
    color: #fff;
}
.finance_statistic_cont_item_red__2CTxb p{
    padding-left: 20px;
}
.finance_statistic_cont_item_orange__163F7{
    width: 100%;
    border: 4px solid #cf8000;
    border-radius: 10px;
    margin-bottom: 10px;
}
.finance_statistic_cont_item_header_orange__LIVOv{
    width: 100%;
    background: #cf8000;
    text-align: center;
    color: #fff;
}
.finance_statistic_cont_item_orange__163F7 p{
    padding-left: 20px;
}
.finance_statistic_cont_item_blue__2E8am{
    width: 100%;
    border: 4px solid #16c0cf;
    border-radius: 10px;
    margin-bottom: 10px;
}
.finance_statistic_cont_item_header_blue__1NjrK{
    width: 100%;
    background: #16c0cf;
    text-align: center;
    color: #fff;
}
.finance_statistic_cont_item_blue__2E8am p{
    padding-left: 20px;
}
.finance_statistic_cont_item_black__3T5aa{
    width: 100%;
    border: 4px solid #446062;
    border-radius: 10px;
    margin-bottom: 10px;
}
.finance_statistic_cont_item_header_black__X1WdE{
    width: 100%;
    background: #446062;
    text-align: center;
    color: #fff;
}
.finance_statistic_cont_item_black__3T5aa p{
    padding-left: 20px;
}
.finance_pop__3Dt5G{
    height: 20vh;
    overflow-y: scroll;
}
.finance_title_2__3YWZA{
    text-align: center;
    margin: 10px auto 5px;
    padding-top: 10px;
}
.ant-layout-sider-zero-width-trigger{
    top: 0;
}
.events {
    margin: 0;
    padding: 0;
    list-style: none;
}
.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 28px;
    text-align: center;
}
.notes-month section {
    font-size: 28px;
}
.ant-radio-group {
    text-align: center;
    margin-right: 10px;
    width: 170px !important;
}
.ant-radio-group label{
    margin: 0 10px 0 10px !important;

}


.ant-picker-calendar-header {
    display: flex;
    justify-content: flex-end !important;
    padding: 12px 0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
/*    line-height: 6px !important;*/
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
/*    height: 60px!important;*/
}
.profile_profile_container__1gcbU{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    min-width: 360px;

}
.profile_profile_container_main__2xi7z{
    width: 95%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    height: 80vh;
}
.profile_reports_cont__1YSRk{
    width: 90%;
    margin: 20px auto;

}
@media screen and (max-device-width: 360px) {
    .profile_profile_container__1gcbU{
        width: 90%;
        /* margin: 0 auto; */
        margin-left: 364px;
        padding-left: 20px;
        box-sizing: border-box;
        min-width: 600px;
        overflow-x: scroll;
    }
    .profile_profile_container_main__2xi7z{
        height: 75vh;
        flex-wrap: wrap;

    }
}
.profile_reports_item__1z7NS{
    width: 200px;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    margin: 5px auto;
    text-align: center;
    letter-spacing: 2px;
    padding: 5px;
}
.profile_report__title__YzGh4{
    width: 100%;
    text-align: center;
    padding: 10px;
    font-weight: 600;
}
.profile_listTable__KZt3m{
    width: 100%;
    margin: 0 auto;
}
.profile_report_calendar_item__3fO7X{
    background-color: #7cb305;
    color: #fff;
    text-align: center;
}
.profile_report_calendar_item_all__3CYY8{
    background-color: #7cb305;
    color: #fff;
    text-align: center;
    width: 70%;
    font-size: 12px;
    display: inline-block;
    float: right;
    cursor: pointer



}
.profile_modal_cont__36EwU{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: flex-start;
}
.profile_modal_item__tOPCX{
    width: 50%;
}
.profile_images__15BSq{
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
}
.profile_images__15BSq img{

    margin: 5px;
}
.profile_obj_name__2lw55{
    font-size: 20px !important;
}

.profile_calendar_all__rleEk .profile_ant-picker-calendar-date-content__1S5I_{


    height: auto !important;

}
.profile_addStaffBtn__1QOlp{
    width: 100%;
    display: block;
    margin: 5px 0 5px 20px;
    height: 25px;
}
*{
    margin: 0;
    padding: 0;
}


.home_video__3w2Qm{
    width: 100%;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    height: auto;
}
.home_ant-layout-content__1xndD, .home_site-layout-background__2QKBK{
    padding: 0;
}
.countPack,.count{
    display: block;
    float: right;
    width: 50%;
    text-align: right;
}
.count{

}
.countPack{

}
.containerCount{
    display: block;
    width: 100%;
}
.radiomodule{

}
.radiomodule span{

}

.ant-radio-button-wrapper{
    color: rgba(0, 0, 0, 0.85) !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #fff !important;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {

}
.ant-radio-group-small .ant-radio-button-wrapper {

    font-size: 12px;
}
.ant-radio-group {
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
}


.ant-radio-button-wrapper{

}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {

    font-size: 12px;

}
.select_measure{
    border: 1px solid #ccc;
    padding-top: 5px;
    height: 120px;

}
.ant-modal-body p{
    margin: 5px auto;

}
.select_measure p{
    margin-bottom: 0 !important;
}
.ant-input{
    width: 200px;

}
.label{
    text-align: center;
    float: left;
    display: block;
    width: 120px;
    line-height: 30px;
}
.label_3{
    text-align: left;
    float: left;
    display: block;
    width: 180px;
    line-height: 30px;

}
.label_2{
    text-align: center;
    float: right;
    display: block;
    line-height: 30px;
}
.label_cont{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
}
.tabs_of_storeTypes{
    margin: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;

}
.hide{
    display: none;
}
.btnTransfer{
    margin-right: 5px;
}

.ant-btn-primary.changeIco {
    color: #fff;
    border-color: #fff;
    background: #e52e05c7;
}
.transfer{
    margin: 10px auto;
    width: 90%;
}
.example{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}
.addBtn{
    margin-right: 10px;
    float: right;
}
.inpChange, .sel{
    margin-bottom: 5px;
}
.label_4{
    margin-bottom: 10px;
    float: right;
}
.ant-form-item-label label {
color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker{
    float: left;
    margin-left: 98px;
}
.store_container{

    margin: 0 0 0 10px;
    height: 76vh;

}
.storeBtns{
    margin: 0 0 0 10px;
    height: 5vh;
}
.store_tabs{
    margin: 0 0 0 10px;
    height: 7vh;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.ant-table-sticky-holder{
    min-height: 10vh;
    font-size: 12px;
}
.ant-layout-footer{
    height: 5vh;
}
.search{
    width: 200px;
    display: block;
    margin-right: 10px;
    float: left;

}
.ant-select{
    margin-bottom: 10px;
}
.ant-input {
    width: 62%;
    margin: 5px auto;
}
.store_store_btn__2Gvye{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
}
.store_store_btn__2Gvye button{
    margin: 0 10px;
}

.store_btn__1__2Z62a{
    margin: 0 auto;
    min-height: 5px;
}
*{
    padding: 0;
}

.planning_addBtn__2HzS4{
    margin: 10px 0;
}
.planning_content__3w-Hq{
    margin: 10px auto;
    width: 95%;


}
.planning_step__KYp2Q{
    margin: 30px auto;
}
.planning_chbx__2M4ML{
    color: rgba(0, 0, 0, 0.85) !important;
}
.planning_menu__2adZI{
    height: 100%;
    margin: 10px auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
}

.planning_carsCard__3QKuo{
    width: 200px;
     cursor: pointer;
     -webkit-filter: grayscale(100%);
             filter: grayscale(100%);
    display: block;
    float: left;
 }
.planning_titleCard__1j99a{
   text-align: center;
}

.planning_propsCard__2J2lA{
    width: 200px;
    cursor: pointer;
    display: block;
    text-align: center;
}
.planning_titlePropsCard__3jjpH{
    text-align: center;
}




.planning_active__2KxWQ, .planning_carsCard__3QKuo:hover{
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    border-bottom: 2px solid #1890ff;
}

.planning_carImg__314tJ{
    width: 100%;

}
.planning_containerImg__HWwfd{


}

.planning_title__1BVcW{

}


.planning_anticon__1cXLs svg {
   margin-left: 0px !important;
}
.planning_ant-tag__2UrRP {
    margin-right: 0px !important;
}


.planning_example__1eO1N{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}


.planning_card_content__1BsId{
    height: 75vh;
}
.planning_card_container__3oGKu{
    width: 90%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    margin: 0 auto;

}

.planning_preview__container__3NNrf{
    width: 100%;
}

.planning_preview__title__3K8wn{
    width: 100%;
}
.planning_preview__container_image__2qBpe{
    width: 100%;
}

.planning_planning__menu__20W41{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
}
.planning_planning__menu__item__1NNi8{
    width: 130px;
    margin: 10px 2%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    text-align: center;

}
.planning_planning__menu__item__1NNi8 img{
    width: 100%;

}
.planning_planning__menu__item__1NNi8:hover{
    border: 1px solid #1890ff;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    font-weight: bold;

}
.planning_planning__menu__item__title__QMv2R{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

}
.planning_planning__menu__header__29P6W{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    margin: 10px auto;
}
.planning_planTitle__select__cont__1Kgui{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px auto 0;
}
.planning_planTitle__select__title__3aO0O{
    line-height: 20px;
    min-width: 80px;
    font-size: 12px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.planning_row__container__2cdvy{

}
.planning_comment__2-sKC{
    width: 100%;
    font-size: 12px;
}
.planning_comments_content__2pbyJ{
    min-height: 150px;
    max-height: 150px;
    text-align: left;
}
.planning_comment__title__3jI0J{
    font-size: 16px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    color: rgba(0, 0, 0, 0.45);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.planning_team_title__jYJkL{
    width: calc(100% - 10px);
    text-align: center;
    background: rgb(16,134,103);
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    padding: 5px;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
}

.planning_team_title__name__AZwUe{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.planning_team_title__props__8dJWy{
    font-size: 13px;
    width: 100%;

}
.planning_listTable__2U8QT{
    width: 80%;
    margin: 0 auto;
}
.planning_report_calendar_item__2u5jz{
    background-color: #0f3d31;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
}
.planning_calendar_title__2XXLD{
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 5px;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 600;
}


.planning_change__popover__read__2ZmiL{
    background-color: #33b200;
    color: #fff;
    opacity: .8;
    cursor: pointer;
}
.planning_change__popover__change__36oCq{
    background-color: #f50;
    color: #fff;
    opacity: .8;
    cursor: pointer;
}
.planning_change__popover__change__36oCq:hover, .planning_change__popover__read__2ZmiL:hover{
    opacity: 1;
}

.planning_inputForCommentCont__1z68z{
    width: 95%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
}
.planning_inputForComment__3zd9j{
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 12px;
    margin: 10px auto;
}
.ant-card-body{
    padding: 0;
}
.ant-card-actions{
    float: left;
}
.ant-list-item{
    padding: 5px;
}
.text{
    width: 100% !important;
}
.comment{
    height: 150px;
}
.buttunCont{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.buttunCont button{
    margin: 5px;
    border-radius: 0;
}
.pdfmodal{
    width: 100% !important;
}
.pdfview{
    width: 100%;
    height: 72vh;
}
.ant-list-item-meta-description {

    font-size: 12px;

}
.ant-card-actions > ul {
    position: absolute;
    bottom: 10px;
    left: 10px;

}
.ant-card-actions > li {
margin: 0;
}
.ant-checkbox + span {
    padding-right: 15px;
    padding-left: 10px;
    min-width: 142px;
    margin-right: 15px;
    text-overflow: ellipsis;
    max-width: 142px;
    width: 141px;
    white-space: nowrap;
    overflow: hidden;
}

.ant-select {
    margin-bottom: 10px;
    font-size: 12px;
    min-width: 170px;
}
.ant-modal-body {
overflow-y: scroll;
    max-height: 70vh;
    min-height: 70vh;
}
.ant-radio-group {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
}

.ant-picker-cell-disabled div .ant-picker-calendar-date-content div{
    background-color: #ccc;
}
.ant-checkbox + span {
    min-width: 400px !important;
}

.label_cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.label_name{
    width: 200px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
}
.label_props{
    width: 100px;
    display: block;
    float: left;
    margin-left: 5px;
    box-sizing: border-box;
    min-width: 100px;
}
.label_props__one{
    width: 150px;
    display: flex;
    float: left;
    box-sizing: border-box;
    min-width: 150px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.currentPlan_main_container__2BZUh{
    width: 100%;
    overflow-y: scroll;
    height: 60vh;
}
.currentPlan_main_container_1__2MZYU{
    width: 100%;
    overflow-y: scroll;
    height: 80vh;
    max-width: 800px;
    margin: 0 auto;
}
.currentPlan_planTitle__2_InD{
    width: 100%;
    text-align: center;
    padding: 5px;
    font-weight: 600;
}
.currentPlan_logo__2O_Fc{
    text-align: center;
    width: 100%;
    margin: 15px auto 5px;
}
.currentPlan_logo__2O_Fc img{
    width: 150px;

}
.currentPlan_team_container__1Eh3t{
    width: 95%;
    margin: 10px auto 50px;

}
.currentPlan_team_title__1OScK{
    font-weight: 600;
    padding: 5px;
    box-sizing: border-box;
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    text-align: center;
    color: #fff;
}
.currentPlan_team_props__3EApK{
    padding-left: 10px;
    box-sizing: border-box;
}
.currentPlan_sub_container__2Tjso{
    min-width: 40%;
}
.currentPlan_flex_container__3Ur8p{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
}
.currentPlan_object_container__B7zHs{
    border: 1px solid green;
    border-radius: 10px;
    padding: 10px;
    margin: 10px auto;
}
.currentPlan_main__comment__KD9ON{
    width: 95%;
    margin: 5px auto;
}


.analitics_main__cont__3RPpY{

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
.analitics_button_down__3I7fy{
    width: 292px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.analitics_selobj__2wDKD{
    width: 292px;
}


.photoArchive_textOverImg__fJpfB{
    background: #2222222e;
    color: #fff;
    border-radius: 2px;
    position: relative;
    right: 196px;
    bottom: 10px;
    font-size: 14px;
    width: 100px;
}
.photoArchive_object__select__oxZxS{
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}
.photoArchive_download__cont__y80CA{
    width: 90%;
    margin: 10px auto;
    text-align: center;
}
.mobile_header__3iEag{
    display: flex;
    background: rgb(16,134,103);
    background: linear-gradient(90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    line-height: 22px;
    font-family: 'Noto Sans', sans-serif;
    min-width: 320px;
    width: 100%;

    flex-direction: column;


}
.mobile_status__wdHoy{
    width: 100%;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    color: #a39797;
    height: 8vh;
    min-height: 50px;

}
.mobile_main__1TBeY{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    min-height: 320px;

}
.mobile_mobcontent__3U1-a{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: stretch;
    overflow-y: scroll;
    height: 87vh;
}
.mobile_mobfooter__5vAoA{
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    color: white;
    font-size: 10px;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    line-height: 20px;
    min-height: 20px;
    width: 100%;
    height: 5vh;
}
.mobile_logo__1TChB{

}
.mobile_imgcontainer__11Z13{
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    transition: 1s; /*Скорость перехода состояния элемента*/
    -webkit-animation: mobile_show__r5kAE 4s 1;
            animation: mobile_show__r5kAE 4s 1; /* Указываем название анимации, её время и количество повторов*/
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    -webkit-animation-delay: 100ms;
            animation-delay: 100ms; /* Задержка перед началом */
}

.mobile_noaction__1rzwZ{
    width: 100%;
    text-align: center;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    color: #939191;
    display: flex;
    align-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 500;
}


.mobile_example__3WZnF{
    margin: 30% auto;
    text-align: center;
    border-radius: 4px;
}

@-webkit-keyframes mobile_show__r5kAE{
    0%{
        opacity:0;
    }
   50% {
        opacity:1;
    }
    60% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@keyframes mobile_show__r5kAE{
    0%{
        opacity:0;
    }
   50% {
        opacity:1;
    }
    60% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}
@-webkit-keyframes mobile_hide__3KuPy{
    0%{
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes mobile_hide__3KuPy{
    0%{
        opacity:0;
    }
    100% {
        opacity:1;
    }
}



 .mobile_button_container__GI3Ss{
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-content: center;
     align-items: center;
     height: 80vh;
 }
 .mobile_button_container_up__PIBMu{
     width: 100%;
     display: flex;
     justify-content: flex-start;
     align-content: center;
     align-items: center;

     flex-direction: row;
 }
 .mobile_mobile_btn__21VaM{
    width: 70%;
    margin: 10px auto;
    display: block;
 }
.mobile_mobile_btn_1__iXW9l{
    width: 50%;
    margin: 20px auto;
    display: block;
}

 .mobile_mobile_btn_up__22ed3{
     width: 50px;
     text-align: center;
 }
 .mobile_navigate__1On_N{
     width: 50px;
     text-align: center;
 }
 .mobile_navigate_cont__1s4Wt{
     position: absolute;
     bottom: 35px;
     left: 20px;
 }
 .mobile_navigate_cont_r__1zrEV{
     position: absolute;
     bottom: 35px;
     right: 20px;
 }
 .mobile_chekContainer__qDflm{
     overflow-y: scroll;
     margin-top: 10px;

 }

 .mobile_uploadbtn__2xIvM{
     display: block;
     width: 200px;
     margin: 0 auto;
 }

 .mobile_uploadCont__3Qb8y{
     width: 100%;
     margin: auto;
     display: block;
     padding: 15px;
     text-align: center;
     height: 10vh;
 }
 .mobile_reportTitle__2teZj{
     width: 100%;
     text-align: center;

     color: #9f9f9f;
     font-weight: 500;
     display: block;
 }
 .mobile_reportTitle_new__3v1cm{
     width: 100%;
     text-align: center;
     background-color: #ffffff;
     color: #9f9f9f;
     margin-top: 10px;
     margin-bottom: 20px;
     font-weight: 500;
     display: block;
 }
 .mobile_comment__2llTA{
     width: 90%;
 }

 .mobile_comment_1__3sZ0h{
     width: 200px !important;
     text-align: center;
 }
.mobile_time_cont__1CnS2{
    width: 100%;
}
 .mobile_timePicker__2Tjo9{
     width: 60%;
     display: block;
}
 .mobile_timePicker__2Tjo9 input{
     font-size: 14px;
 }
.mobile_time__1PC8R{
width: 100%;
    display: block;
    margin: 5px auto;
}
.mobile_timeLabel__3ka8E{
width: 100%;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
}
.mobile_next__3WJQg{
    display: block;
    width: 200px;
    margin: 10px auto;
}
.mobile_uploadContainer__3XeCT{
    width: 90%;
    margin: 20px auto;
    text-align: center;
    border: 1px dashed #ccc;
}
.mobile_status_line__2n41X{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
}

.mobile_new__plan__container__3_Hrw{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    height: 87vh;
    overflow-y: scroll;

}
.mobile_new__plan__title__MaCMw span{
    font-weight: 600;
}

.mobile_new__plan__staff_container__r95lA{
    width: 100%;
    background-color: #f1ee97;
    color: #525652;
    padding: 0 10px 10px 10px;
    margin: 0 auto;
}
.mobile_new__plan__staff_title__2ID07{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #292a1d;
    padding: 10px;
}
.mobile_new__plan__staff_content__3xUVP{
    width: 100%;
    background-color: #ffff;
    padding: 10px;
}
.mobile_new__plan__objects_container__39lKd{
    width: 100%;
    background-color: #adf197;
    color: #525652;
    padding: 0 10px 10px 10px;
    margin: 10px auto;

}
.mobile_new__plan__objects_title__3JOAe{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #292a1d;
    padding: 10px;
}
.mobile_new__plan__objects_content__1pv9z{
    width: 100%;
    background-color: #ffff;
    padding: 10px;
    font-size: 12px;
}
.mobile_new__plan__objects_content__1pv9z div, .mobile_new__plan__staff_content__3xUVP div{
    font-size: 12px;
}
.mobile_new__plan__mobile_btn__1yC72{
    width: 150px;
    margin: 10px auto 0;
    font-size: 12px;
    display: block;
    height: 25px;
    line-height: 12px;
    border-radius: 0 !important;
}
.mobile_new__plan__container__report__1PcNY div{
    font-size: 12px;
    box-sizing: border-box;

}
.mobile_new__plan__report__title__2msfX{
    text-align: center;
    padding: 10px;
    font-size: 16px!important;
    font-weight: 600;

}
.mobile_new__plan__container__report__1PcNY{
    max-height: 87vh;
}

.mobile_oldreport_workname__3wUR0{
    width: 100%;
    background: #adf197;
    color: #525652;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px auto;
    font-size: 12px;
}
.mobile_oldreport_comment__3pxxk{
    width: 100%;

    color: #525652;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px auto;
    font-size: 12px;
}
.mobile_oldreport_cont__34wsl{
    height: 80vh;
    overflow-y: scroll;
}
.mobile_work__cont__3SYab{
    border: 1px solid #ccc;
    margin-bottom: 5px;
}
.mobile_radio__cont__Y0PpC{
    width: 100%;
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: space-around;
    flex-wrap: nowrap;
}

.ant-upload-list {

    overflow-y: scroll;
    text-align: center;
}
.novision{
    display: none;
}
.ant-form-item-control-input-content {

    width: 200px;
}

.sel{
    width: 50%;
    margin: 5px auto;
    display: block;
}
.ant-input{
    width: 100%;
}
.ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-bottom: 0;

}
.ant-picker {
     float: none;

    margin: 5px auto 20px auto;
}

.ant-btn-primary {
    color: #fff;
    /* border-color: #1890ff; */
    /* background: #1890ff; */
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 10px;

}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #cccccc;
    background: linear-gradient(
            90deg, rgba(16,134,103,1) 0%, rgba(15,15,15,1) 35%, rgba(14,29,25,1) 65%, rgba(16,134,103,1) 100%);
    border: none;
    border-radius: 10px;
}
.ant-checkbox + span {
    padding-right: 15px;
    padding-left: 10px;
    min-width: 250px;
    margin-right: 15px;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    color: #545f60;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #e4eced !important;
}
