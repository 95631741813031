

.textOverImg{
    background: #2222222e;
    color: #fff;
    border-radius: 2px;
    position: relative;
    right: 196px;
    bottom: 10px;
    font-size: 14px;
    width: 100px;
}
.object__select{
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}
.download__cont{
    width: 90%;
    margin: 10px auto;
    text-align: center;
}