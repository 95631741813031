.addStaffBtn{
    width: 100%;
    display: block;
    margin: 5px 0 5px 20px;
    height: 25px;
}
.addStaffCont{
    height: 85vh;
    overflow-y: scroll;
}
.exit{
    width: 100%;
    text-align: right;
    margin: 10px 0;
    padding-right: 20px;
}
.checkaval{
    display: block;
    float: left;
}