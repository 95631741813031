.store_btn{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
}
.store_btn button{
    margin: 0 10px;
}

.btn__1{
    margin: 0 auto;
    min-height: 5px;
}